import React from 'react';
import { string } from 'prop-types';
import { Box, Heading } from '@chakra-ui/react';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, IS_BOY_FIRST, THE_BRIDE } from '@/constants';
import { BG_WELCOMING_SECTION } from '@/constants/assets';

function WelcomeV4({ ...rest }) {
  return (
    <Box
      padding="32px"
      bgColor="bgPrimary"
      bgImage={`url(${BG_WELCOMING_SECTION})`}
      bgSize="cover"
      bgPos="center"
      minHeight="80vh"
      boxShadow="inset 0 0 0 2000px rgb(0 0 0 /10%)"
      {...rest}
    >
      {/* Dear Section */}
      <Heading
        marginTop="42px"
        textColor="secondaryColorText"
        fontWeight="normal"
        fontSize="xs"
        textAlign="center"
        letterSpacing="2px"
        marginBottom="12px"
        fontFamily="body"
      >
        THE WEDDING OF
      </Heading>
      <Box
        textAlign="center"
        textColor="secondaryColorText"
        textTransform="uppercase"
        letterSpacing="4px"
      >
        <Heading fontWeight="normal" fontSize="2xl">
          {THE_BRIDE}
        </Heading>
        {false && (
          <>
            <Heading fontWeight="normal" fontSize="4xl">
              {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &
            </Heading>
            <Heading fontWeight="normal" fontSize="4xl" marginTop="-22px">
              {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
            </Heading>
          </>
        )}
      </Box>
    </Box>
  );
}

WelcomeV4.propTypes = {
  lang: string.isRequired,
};

export default WelcomeV4;
import React, { useMemo } from 'react';
import { scroller } from 'react-scroll';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Text,
  Divider,
  DrawerFooter,
  // hooks
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdClose, MdMenu } from 'react-icons/md';

import useIsDesktopView from '@hooks/useIsDesktopView';
import useInvitation from '@/hooks/useInvitation';
import useLang from '@/hooks/useLang';
import { THE_BRIDE } from '@/constants';
import { IMG_LOGO } from '@/constants/assets';
import { BTN_NAVIGATION_COLOR } from '@/constants/colors';

import useMenuData from './hooks/useMenuData';
import txt from './locales';

function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isDesktopView } = useIsDesktopView();
  const toast = useToast();
  const lang = useLang();

  const menuData = useMenuData();
  const btnRef = React.useRef();
  const isInvitation = useInvitation();

  const filteredMenuData = useMemo(
    () =>
      menuData.filter((i) => {
        if (isInvitation) return true;
        if (!i.invitationOnly) return true;
        return false;
      }),
    [],
  );

  const onClickMenu = (ELEMENT_CONTENT_ID, offset) => {
    try {
      onClose();
      // const element = document.getElementById(ELEMENT_CONTENT_ID).offsetTop;
      scroller.scrollTo(ELEMENT_CONTENT_ID, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset,
      });
    } catch {
      toast({
        title: 'Oops!',
        description: txt.menuNotFound[lang],
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onClickIconMenu = () => {
    isOpen ? onClose() : onOpen();
  };

  return (
    <>
      <IconButton
        ref={btnRef}
        bgColor={BTN_NAVIGATION_COLOR}
        borderRadius="50%"
        position="fixed"
        zIndex="3"
        bottom="12px"
        left="12px"
        size="sm"
        boxShadow="xl"
        onClick={onClickIconMenu}
        icon={isOpen ? <MdClose color="white" /> : <MdMenu color="white" />}
      />
      <Drawer size="xs" placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          maxWidth={isDesktopView ? '500px' : '85%'}
          bgColor="bgPrimary"
          padding="24px 16px"
        >
          <DrawerBody textAlign="right">
            <Image src={IMG_LOGO} width="70px" position="absolute" right="32px" />
            <Box marginTop="120px">
              {filteredMenuData.map((i) => {
                return (
                  <div key={i.value}>
                    <Text
                      fontSize="small"
                      marginTop="24px"
                      textTransform="uppercase"
                      color="mainColorText"
                      letterSpacing="3px"
                      cursor="pointer"
                      onClick={() => onClickMenu(i.value, i.offset)}
                    >
                      {i.name}
                    </Text>
                    {false && <Divider width="100%" marginTop="8px" />}
                  </div>
                );
              })}
            </Box>
          </DrawerBody>
          <DrawerFooter>
            <Box textAlign="center" fontSize="10px">
              <Text color="mainColorText">Created with Love by Invitato</Text>
              <Text color="mainColorText">{`${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}</Text>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Navigation;

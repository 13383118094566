import React from 'react';
import { Heading, Box, Center, useDisclosure, Button, Text } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';

import WithAnimation from '@components/Common/WithAnimation';
import AddToCalendarButton from '@components/Common/AddToCalendar';
import CountDown from '@components/Common/CountDown';
import QRSection from '@/components/Common/QRCard/V2';
import WeddingDetail from './WeddingDetail';
import BaliGuestInformation from './BaliGuestInformation';

import {
  WEDDING_AKAD_LOC_NAME,
  GOOGLE_MAPS_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_TIME,
} from '@/constants';
import { HEADING_STYLES, LINK_STYLES } from '@constants/colors';
import { ID_COUNTDOWN_SECTION } from '@constants/identifier';
import * as ID from '@/constants/identifier';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import useShiftTime from '@hooks/useShiftTime';

function WeddingSection({ ...rest }) {
  const isInvitation = useInvitation();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const lang = useLang();
  const time = useShiftTime();

  return (
    <>
      <Box padding="12px 0 0px 0" bgColor="bgPrimary" {...rest}>
        <WithAnimation>
          <Heading marginBottom="42px" {...HEADING_STYLES} paddingLeft="0px" textAlign="center">
            {isInvitation ?txtWording.title[lang] :"WHEN"}
          </Heading>
        </WithAnimation>
        {/* Wedding info section */}
        <Box padding="0 28px" marginTop="-16px">
          {/* Announcement */}
          {!isInvitation && (
            <WithAnimation>
              <WeddingDetail
                date={`Saturday, December, 2<sup>nd</sup> 2023`}
                time={WEDDING_AKAD_TIME}
                title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                subTitle={false &&
                    <>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_ROAD }} /></Text>
                    </>
                }
                // location={GOOGLE_MAPS_LINK}
              />
            </WithAnimation>
          )}
          
          {/* Invitation General */}
          {isInvitation && time ==="Shift" && (
            <>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_AKAD_TIME}
                  title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                  subTitle={isInvitation &&
                      <>
                        <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                        <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_ROAD }} /></Text>
                      </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time="4.00 - 6.00 PM"
                  title="TEA PAI"
                  subTitle={
                    <>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_RESEPSI_TIME}
                  title={txtWording.reception[lang]}
                  subTitle={
                    <>
                      <Text>*) Invitation valid for 2 persons.</Text>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
            </>
          )}
          
          {/* Invitation Shift 1 Pemberkatan + Tea Pai + Resepsi */}
          {isInvitation && time ==="Tea" && (
            <>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_AKAD_TIME}
                  title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                  subTitle={isInvitation &&
                      <>
                        <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                        <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_ROAD }} /></Text>
                      </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time="4.00 - 6.00 PM"
                  title="TEA PAI"
                  subTitle={
                    <>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_RESEPSI_TIME}
                  title={txtWording.reception[lang]}
                  subTitle={
                    <>
                      <Text>*) Invitation valid for 2 persons.</Text>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
            </>
          )}
          
          {/* Invitation Shift 2 Pemberkatan + Resepsi */}
          {isInvitation && time ==="Resepsi" && (
            <>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_AKAD_TIME}
                  title={MUSLIM_INVITATION ? txtWording.akadMuslim[lang] : txtWording.akadGeneral[lang]}
                  subTitle={isInvitation &&
                    <>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_AKAD_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
              <WithAnimation>
                <WeddingDetail
                  date={`Saturday, December, 2<sup>nd</sup> 2023`}
                  time={WEDDING_RESEPSI_TIME}
                  title={txtWording.reception[lang]}
                  subTitle={
                    <>
                      <Text>*) Invitation valid for 2 persons.</Text>
                      <Text marginTop="8px"><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }} /></Text>
                      <Text><span dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_ROAD }} /></Text>
                    </>
                  }
                  location={GOOGLE_MAPS_LINK}
                />
              </WithAnimation>
            </>
          )}
          
          {false && (
            <WithAnimation>
              <Button {...LINK_STYLES} variant="ghost" padding="0" onClick={onOpen}>
                Bali Guest Information
              </Button>
            </WithAnimation>
          )}
          
          {isInvitation && (
            <WithAnimation>
              <Center>
                <QRSection name={ID.ID_QRCODE} />
              </Center>
            </WithAnimation>
          )}
        </Box>

        <WithAnimation>
          <Heading
            {...HEADING_STYLES}
            textAlign="center"
            paddingLeft="0px"
            margin="52px 0 26px 0"
            name={ID_COUNTDOWN_SECTION}
          >
            COUNTDOWN
          </Heading>
        </WithAnimation>
        <Box width="100%" bgColor="bgSecondary" padding="12px 0 32px 0" color="mainColorText">
          <WithAnimation>
            <Center>
              <CountDown lang="en" />
            </Center>
          </WithAnimation>
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendarButton
                  marginTop="16px"
                  size="sm"
                  text={txtWording.calendar[lang]}
                  fontSize="xs"
                  fontWeight="normal"
                  colorScheme="blackAlpha"
                  color="mainColorText"
                  bgColor="bgAlternative"
                />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </Box>
      {isInvitation && <BaliGuestInformation lang={'en'} onClose={onClose} visible={isOpen} />}
    </>
  );
}

export default React.memo(WeddingSection);
const img = '';

export default [
  {
    img: img,
    title: 'May, 2013',
    description: `We were classmates and each other’s seat partner back in 10th grade.`,
  },
  {
    img: img,
    title: 'December, 2015',
    description: `We were good friends for 2 years until out of the blue we start dating and finally made our relationship official.`,
  },
  {
    img: img,
    title: 'November, 2022',
    description: `After 7 years of being together, Andre got down on one knee and asked the question “Will you marry me?” at Claudia’s most favorite place, Universal Studio.`,
  },
  {
    img: img,
    title: '2 December 2023',
    description: `OUR BIG DAY! And now, we invite you to celebrate the next page of our story. We’ll see you at the altar!`,
  },
];

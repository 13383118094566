import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { AiFillPushpin, AiOutlineArrowDown } from 'react-icons/ai';
import { Box, Skeleton, Flex, SkeletonCircle, Text, IconButton, Center } from '@chakra-ui/react';

import { styWishlishWrapper } from './styles';

function Wishlist({ wishlistData, loading }) {
  const renderLoading = () => {
    const result = [];

    for (let i = 0; i < 2; i++) {
      result.push(
        <Flex alignItems="center" key={`loader-${i}`}>
          <Box marginRight="16px">
            <SkeletonCircle height="40px" width="40px" />
          </Box>
          <Box width="80%">
            <Box boxShadow="md" p={4} borderEndRadius="3xl" borderTopRadius="3xl">
              <Skeleton m="2" height="14px" width="50px" />
              <Skeleton m="2" height="14px" width="100%" />
              <Skeleton m="2" height="14px" width="200px" />
            </Box>
          </Box>
        </Flex>,
      );
    }

    return result;
  };

  const getWishItem = (item, index) => {
    return (
      <Flex alignItems="top" marginTop="16px" key={`wishlist-${index}`}>
        <Box width="100%">
          <Box padding="4px 8px" bgColor="bgSecondary" width="100%">
            <Flex alignItems="center">
              <Text
                color="mainColorText"
                marginRight="8px"
                fontWeight="bold"
                fontSize="xs"
                letterSpacing="1px"
                fontFamily="body"
              >
                {item.nama}
              </Text>
              {item.pinByAdmin === 1 && <AiFillPushpin color="black" />}
            </Flex>
          </Box>
          <Box
            marginTop="2px"
            padding="4px 8px"
            bgColor="bgSecondary"
            width="100%"
            fontFamily="body"
            color="mainColorText"
          >
            <Text fontSize="xs">{item.ucapan}</Text>
            {false && (
              <Text fontSize="xs" color="#908365" marginTop="4px">
                {item.time}
              </Text>
            )}
          </Box>
        </Box>
      </Flex>
    );
  };

  const renderWishlist = () => {
    let finalResult = [];
    let wishlist = [];
    let pinnedWishlist = [];

    wishlistData.forEach((item, index) => {
      if (item.pinByAdmin === 1) {
        pinnedWishlist.push(getWishItem(item, index));
      } else {
        wishlist.push(getWishItem(item, index));
      }
    });

    wishlist = wishlist.reverse();
    finalResult = [...pinnedWishlist, wishlist];

    return finalResult;
  };

  const getHeight = React.useMemo(() => {
    if (wishlistData.length === 0) return '0';
    if (wishlistData.length === 1) return '200px';
    return '300px';
  }, [wishlistData.length]);

  return (
    <Box css={styWishlishWrapper} id="wishlist__box">
      <Box height={getHeight} overflow="scroll" margin="16px 0">
        <Box>{loading && renderLoading()}</Box>
        <Box paddingBottom="0">{!loading && renderWishlist()}</Box>
      </Box>
      {wishlistData.length > 2 && (
        <Center>
          <IconButton
            marginTop="-30px"
            size="sm"
            icon={<AiOutlineArrowDown />}
            isRound
            className="animation-heart button__arrowdown"
          />
        </Center>
      )}
    </Box>
  );
}

Wishlist.propTypes = {
  loading: bool,
  wishlistData: arrayOf(object),
};

Wishlist.defaultProps = {
  loading: false,
  wishlistData: [],
};

export default Wishlist;

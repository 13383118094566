import React from 'react';
import { Box, Text, Divider, Center } from '@chakra-ui/react';

import useInvitation from '@hooks/useInvitation';
import DetailCard from './DetailCard';

import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
// adding comment for trigger build
import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import { MUSLIM_INVITATION } from '@constants/feature-flags';
import txt from './locales.js';
import useLang from '@hooks/useLang';

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary">
      {false && (
        <Center>
          <Divider
            opacity={1}
            bgColor="blackAlpha.200"
            borderBottom="2px solid"
            borderBottomColor="blackAlpha.100"
            maxWidth="200px"
          />
        </Center>
      )}
      <Box padding="0 0 12px 0" {...rest}>
        {MUSLIM_INVITATION 
          ?
            <Text textAlign="center" padding="0 24px" color="mainColorText" fontSize="sm">
              {isInvitation ? (
                <span dangerouslySetInnerHTML={{ __html : txt.openingMuslimInvitation[lang] }} />            
              ) : (
                <span dangerouslySetInnerHTML={{ __html : txt.openingMuslimAnnouncement[lang] }} />
              )}
            </Text> 
          :
            <Text textAlign="center" padding="0 24px" color="mainColorText" fontSize="sm">
              {isInvitation ? (
                <span dangerouslySetInnerHTML={{ __html : txt.openingGeneralInvitation[lang] }} />
              ) : (
                <span dangerouslySetInnerHTML={{ __html : txt.openingGeneralAnnouncement[lang] }} />
              )}
            </Text>
        }
        <DetailCard
          fullName={IS_BOY_FIRST ? `Andre Evan` : GIRL_NAME}
          imgUrl={IS_BOY_FIRST ? IMG_MAN : IMG_GIRL}
          instagramId={IS_BOY_FIRST ? IG_BOY : IG_GIRL}
          description={IS_BOY_FIRST ? BOY_PARENT_NAME : GIRL_PARENT_NAME}
        />
        <Text
          textAlign="center"
          color="mainColorText"
          fontFamily="heading"
          fontSize="5xl"
        >
          {`&`}
        </Text>
        <DetailCard
          fullName={IS_BOY_FIRST ? `Claudia Sergio` : BOY_NAME}
          imgUrl={IS_BOY_FIRST ? IMG_GIRL : IMG_MAN}
          instagramId={IS_BOY_FIRST ? IG_GIRL : IG_BOY}
          description={IS_BOY_FIRST ? GIRL_PARENT_NAME : BOY_PARENT_NAME}
        />
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);
